.linksBlock {
    display: flex;
    justify-content: center;
}

.link {
    display: flex;
    align-items: center;
    margin-right: 6px;
    cursor: pointer;
    height: 2rem;
    margin-top: 6px;
}

.link_border {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 22px;
}


.link_border p {
    font-family: "Industry", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: white;
    padding: 0px 0.5rem;
    text-align: center;
}

.link:last-child {
    margin-right: 0;
}

@media (any-hover: hover) {
    .link {
        transition: opacity .3s ease-in;
    }

    .link:hover {
        opacity: .7;
    }
}
