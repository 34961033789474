.footer {
    background-color: #1d1e29;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
}

.footer .container {
    /* max-width: 1280px; */
    margin: 0 auto;
    padding: 0 4rem;
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-family: "Coalition", serif;
    font-size: 2rem;
    line-height: 2.5rem;
    text-transform: uppercase;
}

.linksBlock {
    display: flex;
    align-items: center;
}

.linksBlock p {
    font-family: "Industry", serif;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-right: 1.5rem;
}

.links {
    margin-right: 1rem;
}

.links:last-child {
    margin-right: 0;
}

@media (min-width: 1920px) {
    .footer .container {
        max-width: 80%;
    }
}

@media (min-width: 1440px) and (max-width: 1920px) {
    .footer .container {
        max-width: 90%;
    }
}


@media only screen and (max-width: 768px) {
    .footer {
        text-align: center;
    }

    .footer .container {
        padding: 0 1rem;
    }

    .content,
    .linksBlock {
        display: block;
    }

    .logo {
        margin-bottom: 1.75rem;
        font-size: 1.5rem;
        line-height: 2.25rem;
    }

    .linksBlock p {
        padding-right: 0;
    }
}
