.container {
    display: inline-block;
    padding: 8px 12px;
    margin-left: 24px;
    background: rgba(29, 30, 41, 0.5);
    border-radius: 4px;
    font-family: "Industry", serif;
    font-size: 1rem;
    line-height: 1.25rem;
    color: white;
    position: relative;
    cursor: pointer;
}

.container img {
    display: inline-block;
    margin-left: 12px;
}

.items {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background: rgba(29, 30, 41, 0.5);
    border-radius: 4px;
    overflow: hidden;
    height: 0;
    opacity: 0;
    pointer-events: none;
    will-change: height;
}

.items>div>div {
    padding: 8px 12px;
}

.item+.item {
    margin-top: 8px;
}

.items[closing]:not([open]) {
    opacity: 1;
    animation: close 300ms forwards;
}

.items[open] {
    opacity: 1;
    pointer-events: all;
    animation: open 300ms forwards;
}

@keyframes open {
    0% {
        height: 0px;
    }

    100% {
        height: var(--h, 10px);
    }
}

@keyframes close {
    0% {
        height: var(--h, 10px);
    }

    100% {
        height: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        width: 100%;
        margin-left: 0;
        text-align: left;
    }
}