.header {
    /* background-color: #2F3040; */
    /* background-color: rgba(47, 48, 64, 0.4); */
    padding: 0.75rem 0;
    width: 100%;
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 20;
    top: 0;
    left: 0;

}

.header .container {
    /* max-width: 1280px; */
    margin: 0 auto;
    padding: 0 4rem;
}

.mobile {
    display: none;
}

.header__navMenu {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__navMenu__blockLinksLeft__linkItem {
    text-decoration: none;
    margin-right: 1rem;
    font-family: "Industry", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    transition: color .3s ease-in;
}

.header__navMenu__blockLinksLeft__linkItem:last-child {
    margin-right: 0;
}

.header__navMenu__blockLogo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 2;
}

.header__navMenu__blockLinksRight__linkItem {
    text-decoration: none;
    margin-right: 1rem;
    font-family: "Industry", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #ffffff;
    cursor: pointer;
    transition: color .3s ease-in;
}

.active {
    text-decoration: underline;
    color: rgba(215, 252, 81, 1);
}

.header__navMenu__blockLinksLeft__linkItem:hover,
.header__navMenu__blockLinksRight__linkItem:hover {
    color: rgba(215, 252, 81, 1);
}

.header__navMenu__blockLinksRight__btn_lightGreen {
    padding: 0.5rem 1rem;
    background-color: #d7fc51;
    border: 0;
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #1D1E29;
    cursor: pointer;
    transition: opacity .3s ease;
}

.header__navMenu__blockLinksRight__btn_lightGreen:hover,
.header__navMenu__blockLinksRight__btn_lightGreen:active {
    opacity: .7;
}

@media (min-width: 1920px) {
    .header .container {
        max-width: 80%;
    }

    .header__navMenu__blockLogo {
        padding-top: 1rem;
    }

}

@media (min-width: 1900px) {
    .header__navMenu__blockLinksRight__linkItem {
        margin-right: 0.5rem;
        font-size: 0.8rem;
    }

}
@media (min-width: 2200px) {
    .header__navMenu__blockLinksRight__linkItem {
        font-size: 1rem;
    }

}

@media (min-width: 1440px) and (max-width: 1920px) {
    .header .container {
        max-width: 90%;
    }
}

@media only screen and (max-width: 1245px) {
    .header {
        padding: 1rem 0;
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
    }

    .header .container {
        padding: 0 1rem;
    }

    .header__navMenu {
        display: none;
    }

    .header__navMenu__blockLinksLeft,
    .header__navMenu__blockLinksRight {
        display: flex;
        flex-wrap: wrap;
    }

    .header__navMenu__blockLinksLeft__linkItem,
    .header__navMenu__blockLinksRight__linkItem {
        width: 100%;
        margin-right: 0;
        padding: 1.25rem;
        font-size: 1.5rem;
    }

    .header__navMenu__blockLogo {
        display: none;
    }

    .mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header__navMenu__blockLinksRight__btn_lightGreen {
        margin-right: 1.18rem;
    }

    .buttons {
        display: flex;
        align-items: center;		
    }

    .button {
        z-index: 10;
        min-width: 48px;
        min-height: 48px;
    }

    .button img {
        width: 100%;
        height: 100%;
    }

    .header__open {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        background: #1D1E29;
        text-align: center;
        padding-top: 1.875rem;
        overflow-y: scroll;
    }

    .hide {
        display: none;
    }
	    .header__navMenu__blockLinksRight__btn_lightGreen {
        margin-right: 0.625rem;
        padding: 0.2rem;			
    }
	
}

@media only screen and (max-width: 370px) {
    .header__navMenu__blockLinksRight__btn_lightGreen {
        margin-right: 0.625rem;
        padding: 0.2rem;	
		
    }
}