.map {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow-x: hidden;
}

.mapBG {
    position: relative;
    overflow: hidden;
    background: url("/image/spark.webp");
    background-position: -35% 145%;
    background-size: 60%;
    background-repeat: no-repeat;
}

.map .container {
    /* max-width: 1280px; */
    margin: 0 auto;
    padding: 0 4rem;
}

.blockMainTexts {
    width: 530px;
}

.mobileBlocks {
    display: none;
}

.bgCharacters {
    background: url("/image/bgCharacters.webp") no-repeat;
    background-size: contain;
}

.bgPlay {
    background: url("/image/bgP2E.webp") no-repeat;
    background-size: 100% 100%;
}

.bgSoon {
    background: url("/image/bgSoon.webp") no-repeat;
    background-size: 118% 116%;
    background-position: 93% 65%;
}

.bgRoadmap {
    background: url("/image/bgRoadmap.webp") no-repeat;
    background-size: 100% 80%;
    position: relative;
}

.bgPartners {
    background: url("/image/partnersBg.webp") no-repeat;
    background-size: 100% 100%;
}

.titleAboutExplor {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.625rem;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 80px;
}

.titleHowManyMeters {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    text-transform: uppercase;
    color: #ffffff;
}

.titleAboutSquare {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
}

.titleAboutHowManyPlay {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #ffffff;
}

@media (min-width: 1920px) {
    .map .container {
        max-width: 80%;
    }
    .blockMainTexts {
        width: 800px;
    }
}

@media (min-width: 1440px) and (max-width: 1920px) {
    .map .container {
        max-width: 90%;
    }
}

@media only screen and (max-width: 1024px) {
    .bgPlay {
        background: url("/image/p2emobile.webp") no-repeat;
        background-size: cover;
    }

    .mapBG {
        background: url(/image/sparkMobile.webp);
        background-position: left bottom;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .bgSoon {
        background: url("/image/robotSparks.webp") no-repeat;
        background-size: 119% 97%;
        background-position: 151% -42%;
    }

    .bgRoadmap {
        background: url("/image/roadmapMobile.webp") no-repeat;
        background-size: cover;
    }

    .map {
        padding-top: 0;
        padding-bottom: 325px;
        text-align: center;
    }

    .mobileBlocks {
        display: block;
    }

    .map .container {
        padding: 0 1rem;
    }

    .blockMainTexts {
        width: 100%;
        text-align: center;
        margin-bottom: 82px;
    }

    .titleAboutExplor {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }

    .titleHowManyMeters {
        display: none;
    }

    .titleAboutSquare {
        display: none;
    }

    .titleAboutHowManyPlay {
        display: none;
    }

    .mobileTitle {
        font-family: 'Coalition', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        text-transform: uppercase;
        color: #ffffff;
    }

    .mobileText {
        font-family: 'Industry', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        color: #ffffff;
        margin-bottom: 1.5rem;
    }
}
